import React from "react"
import Identicon from "identicon.js"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import Button from "react-bootstrap/esm/Button"
import { Link } from "gatsby"

function toHex(txt: string) {
  const encoder = new TextEncoder()
  return Array.from(encoder.encode(txt))
    .map(b => b.toString(16).padStart(2, "0"))
    .join("")
}

export type ArticleItemProps = {
  label?: string
  writer: string
  picture?: string
}

export const WriterItem = (props: ArticleItemProps) => {
  const picture =
    props.picture ||
    `data:image/png;base64,${new Identicon(
      toHex(props.writer).padStart(15, "-"),
      {
        background: [0, 0, 0, 255],
        size: 140,
      }
    ).toString()}`

  return (
    <Row style={{ marginBottom: "10px", width: "100%" }}>
      <Col xs={3}>
        <img
          src={picture}
          style={{
            maxWidth: "60px",
            maxHeight: "60px",
            width: "100%",
            borderRadius: "50px",
          }}
        />
      </Col>
      <Col xs={6}>
        <h5
          style={{
            marginBottom: "0",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.label === "Influencer" && (
            <i style={{ marginRight: "3px" }} className="bi bi-star-fill"></i>
          )}
          {props.label === "DAO" && (
            <i
              style={{ marginRight: "3px" }}
              className="bi bi-collection-fill"
            ></i>
          )}
          {props.writer}
        </h5>
      </Col>
      <Col xs={3}>
        <Link to={`/writer/${props.writer}`}>
          <Button size="sm" variant="outline-primary">
            view
          </Button>
        </Link>
      </Col>
    </Row>
  )
}
