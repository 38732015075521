import React, { useEffect, useState } from "react"
import { WriterItem } from "../components/WriterItem"
import { Page } from "../components/Page"

type Writer = {
  twitterHandle: string
  label: string
  picture?: string
}

const listWriters = async () => {
  const resultRes = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}listWriters/desc`
  )
  return resultRes.json()
}

export default function Home({ params }: { params: { query: string } }) {
  const [writers, setWriters] = useState([] as Writer[])

  useEffect(() => {
    listWriters().then(setWriters)
  }, [params.query])

  return (
    <Page>
      <h3>Newest writers</h3>
      <div className="threeToOneColumnCount">
        {writers.map(writer => (
          <div key={writer.twitterHandle}>
            <WriterItem
              label={writer.label}
              writer={writer.twitterHandle}
              picture={writer.picture}
            />
          </div>
        ))}
      </div>
    </Page>
  )
}
